import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      light: "#868686",
      main: "#404751",
    },
    secondary: {
      main: "#b4c47b",
    },
    alternate: {
      main: "#24272d",
    },
  },
  typography: {
    fontFamily: "Raleway",
    h1: {
      fontFamily: "Gafata",
    },
    h3: {
      fontFamily: "Raleway",
    },
    h2: {
      fontFamily: "Gafata",
    },
    button: {
      fontFamily: "Open Sans",
    },
  },
});

export default theme;
