exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-hand-rejuvenation-js": () => import("./../../../src/pages/hand-rejuvenation.js" /* webpackChunkName: "component---src-pages-hand-rejuvenation-js" */),
  "component---src-pages-hand-surgery-and-more-js": () => import("./../../../src/pages/hand-surgery-and-more.js" /* webpackChunkName: "component---src-pages-hand-surgery-and-more-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nationwide-hand-surgery-js": () => import("./../../../src/pages/nationwide-hand-surgery.js" /* webpackChunkName: "component---src-pages-nationwide-hand-surgery-js" */),
  "component---src-pages-new-patient-js": () => import("./../../../src/pages/new-patient.js" /* webpackChunkName: "component---src-pages-new-patient-js" */),
  "component---src-pages-patient-education-js": () => import("./../../../src/pages/patient-education.js" /* webpackChunkName: "component---src-pages-patient-education-js" */)
}

